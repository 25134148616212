import { useMemo, useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import TokenService from '@services/TokenService';
import useAccountIdRoute from 'hooks/useAccountIdRoute';
import { pick } from 'lodash';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import AnalyticsEventLogger from 'utils/AnalyticsEventLogger';
import { getOrgColor } from 'utils/getRandomColor';

import { Divider, Grid, MenuItem, Popover } from '@mui/material';
import {
  bindHover,
  bindMenu,
  usePopupState,
} from 'material-ui-popup-state/hooks';

// components
import * as Sentry from '@sentry/browser';
import { Text } from '@subframe/core';
import { Organization } from 'api/models';
import classNames from 'classnames';
import Iconify from 'components/Iconify';
import InitialsIcon from 'components/InitialsIcon';
import { RouterLink } from 'components/RouterLink';
import { AtomicTooltip } from 'components/design-system';
import { Stack } from 'components/utils/Stack';
import { toastAutoHideDuration } from 'constants/toasts';
import useUserAccountState from 'hooks/useUserAccountState';
import { Avatar, Button } from 'subframe/index';
import MenuPopover from '../../../components/MenuPopover';
import styles from './AccountPopover.module.scss';

const MENU_FLEX = `-${200}px`;

const OrganizationCard = ({
  title,
  onClick,
  active,
  color,
}: {
  title: string;
  active: boolean;
  color: string;
  onClick: () => void;
}) => {
  const theme = useTheme();

  return (
    <AtomicTooltip tooltipContent={title}>
      <Grid
        onClick={onClick}
        container
        alignItems={'center'}
        sx={{
          padding: theme.spacing(2),
          cursor: 'pointer',
          background: active ? theme.palette.grey[800] : '',
        }}
      >
        <InitialsIcon
          height="32px"
          width="32px"
          initials={title?.charAt(0).toUpperCase()}
          color={color}
        />

        <Stack
          direction="row"
          spacing={2}
          justifyContent="center"
          alignItems={'center'}
        >
          <Text id="org-name" className={styles['text-orgName']} variant="body">
            {title}
          </Text>
          {active && <Iconify icon="ion:checkmark" />}
        </Stack>
      </Grid>
    </AtomicTooltip>
  );
};

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const { user, currentOrganization, organizations, account, tokens } =
    useUserAccountState();
  const { enqueueSnackbar } = useSnackbar();

  const { logEvent, reset } = AnalyticsEventLogger();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const { logout } = TokenService();
  const navigate = useNavigate();
  const SwitchOrgError =
    'Something went wrong while switching orgs. Please try again or escalate to Chkk';
  const popupState = usePopupState({
    variant: 'popover',
    popupId: 'swithOrgMenu',
  });

  const path = useAccountIdRoute(
    '/orgs/:orgId/accounts/:accountId/organization-settings',
  );

  interface OrganizationWithColor extends Organization {
    color: string;
    accountId?: string;
  }

  // -----------------------

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    logEvent('account-menu');
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleLogout = () => {
    logEvent('logout');
    reset();
    logout();
    navigate(
      import.meta.env.VITE_LOGOUT_REDIRECT_URL || window.location.origin,
    );
  };

  const switchCurrentOrganization = async (
    organization: Organization,
    accountId: string,
  ) => {
    try {
      navigate(`/orgs/${organization?.id}/accounts/${accountId}/clusters`);
      logEvent(
        'switch-organization',
        pick(organization, ['id', 'name', 'slug', 'status']),
      );
    } catch (err) {
      enqueueSnackbar(SwitchOrgError, {
        variant: 'error',
        autoHideDuration: toastAutoHideDuration,
      });
      Sentry.captureException(err);
    }
  };

  const organizationsWithColors: OrganizationWithColor[] = useMemo(
    () =>
      organizations?.map((item) => {
        const orgAccountIds =
          tokens && tokens[item.id]
            ? Object.values(tokens[item.id])
            : undefined;
        return {
          ...item,
          accountId: orgAccountIds && orgAccountIds[0]?.account_id,
          color: getOrgColor(item?.slug),
        };
      }),
    [organizations, tokens],
  );
  return (
    <>
      <Button
        size="large"
        id="account-popover-button"
        variant="neutral-secondary"
        iconRight="FeatherChevronDown"
        onClick={handleOpen}
        className={styles['button-org']}
      >
        {
          (
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Avatar
                image={user?.profilePicture}
                size="small"
                className="ph-no-capture"
              />

              <AtomicTooltip tooltipContent={currentOrganization?.name}>
                <Text className={styles['text-orgName']}>
                  {currentOrganization?.name}
                </Text>
              </AtomicTooltip>
            </div>
          ) as any
        }
      </Button>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          width: 200,
          p: 0,
          mt: 1.5,
          ml: 0.75,
          marginLeft: '-2px',
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Stack className="p-[10px] pl-[20px] ph-no-capture">
          <Text variant="body" className={styles['text-truncate']}>
            {user?.name}
          </Text>
          <Text
            variant="body"
            color="subtext"
            className={classNames(styles['text-truncate'], 'ph-no-capture')}
          >
            {user?.email}
          </Text>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack className="p-[10px] pl-[20px]">
          <Text variant="body" className={styles['text-truncate']}>
            Current Organization
          </Text>
          <AtomicTooltip tooltipContent={currentOrganization?.name}>
            <Text
              id="current-organization-value"
              variant="body"
              color="subtext"
              className={styles['text-truncate']}
            >
              {currentOrganization?.name}
            </Text>
          </AtomicTooltip>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <RouterLink to={path}>
          <MenuItem
            sx={{ m: 1 }}
            onClick={() => {
              logEvent('organization-settings');
              setOpen(null);
            }}
          >
            Organization Settings
          </MenuItem>
        </RouterLink>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          sx={{ m: 1 }}
          id="switch-organization"
          {...bindHover(popupState)}
        >
          Switch Organization
        </MenuItem>
        <Popover
          {...bindMenu(popupState)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          sx={{ left: MENU_FLEX, top: '-40px' }}
        >
          {organizationsWithColors?.length &&
            organizationsWithColors?.map((org, index) => (
              <OrganizationCard
                key={`${org.id} + ${index}`}
                active={currentOrganization?.id === org.id}
                onClick={() =>
                  // onClick will have no action when impersonation is being used to access the webapp
                  org && org.accountId
                    ? switchCurrentOrganization(org, org.accountId)
                    : void 0
                }
                color={org?.color}
                title={org?.name && org?.name !== '' ? org?.name : org?.slug}
              />
            ))}
        </Popover>
        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
